<template>
  <div>
    <b-modal
      id="modal-danger"
      v-model="isDeleteModalActive"
      ok-variant="danger"
      ok-title="Accept"
      modal-class="modal-normal"
      centered>
      <div class="d-block text-center">
        <p class="font-weight-medium text-28">
          Confirm to Delete
        </p>
      </div>
      <div
        class="mx-auto"
        style="width: 80%;">
        <b-row>
          <b-col
            cols="6"
            class="d-flex justify-content-end">
            <span class="font-weight-bolder">ID :</span>
          </b-col>
          <b-col cols="6">
            {{ formTemplateId }}
          </b-col>
          <b-col
            cols="6"
            class="d-flex justify-content-end">
            <span class="font-weight-bolder">Name :</span>
          </b-col>
          <b-col cols="6">
            {{ formTemplate.name }}
          </b-col>
        </b-row>
      </div>
      <template #modal-footer="{}">
        <div class="mx-auto">
          <b-button
            variant="outline-danger"
            style="margin-right: 12px"
            @click="confirmDelete(formTemplateId)">
            Confirm
          </b-button>
          <b-button
            variant="primary"
            @click="cancelDeleteModal()">
            Cancel
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-card
      @hidden="resetForm()">
      <template #default="{}">
        <ValidationObserver
          #default="{ handleSubmit }"
          ref="FormTemplateDetailForm">
          <b-form
            @submit.prevent="handleSubmit()"
            @reset.prevent="resetForm()">
            <b-row>
              <b-col
                sm="12"
                md="5">
                <TextFieldInput
                  v-model="formTemplate.name"
                  :rules="{ required: true }"
                  name="Name Template Form"
                  label="Name Template Form"
                  placeholder="Name Template Form"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="12">
                <draggable
                  :list="formTemplate.question"
                  :disabled="!enabled"
                  @start="dragging = true"
                  @end="dragging = false">
                  <div
                    v-for="(questionTypeValue, index) in formTemplate.question"
                    :key="index"
                    class="shadow-sm rounded">
                    <b-card
                      border-variant="light"
                      class="padding--top">
                      <div
                        class="text-center">
                        <b-img
                          src="@/assets/images/icons/icon-park_application-menu.svg"
                          alt="icon-park_application-menu"></b-img>
                      </div>
                      <b-row>
                        <b-col
                          sm="12"
                          md="12">
                          <TextFieldInput
                            v-model="questionTypeValue.title"
                            :rules="{ required: true }"
                            name="คำถาม"
                            label="คำถาม"
                            placeholder="คำถาม"
                            trim />
                        </b-col>
                        <b-col
                          sm="12"
                          md="5">
                          <SelectField
                            v-model="questionTypeValue.type"
                            :options="typeAnswerOptions"
                            :rules="{ required: true }"
                            name="ประเภทคำตอบ"
                            placeholder="ประเภทคำตอบ"
                            form-label="ประเภทคำตอบ"
                            label-option="label"
                            return-value="value" />
                        </b-col>
                        <b-col
                          sm="12"
                          md="3">
                          <div
                            v-if="questionTypeValue.type === 'number'"
                          >
                            <TextFieldInput
                              v-model="questionTypeValue.maximumScore"
                              :rules="{ required: true }"
                              name="จำนวนตัวเลขไม่เกิน"
                              label="จำนวนตัวเลขไม่เกิน"
                              placeholder="จำนวนตัวเลขไม่เกิน"
                              trim />
                          </div>
                        </b-col>
                      </b-row>
                      <div class="d-flex align-items-center">
                        <!-- <RadioButton :status.sync="questionTypeValue.isRequired" /> -->
                        <div
                          class="d-flex pr-2"
                          style="padding-top: 6px;">
                          <b-form-checkbox
                            v-model="questionTypeValue.isRequired"
                            class="custom-control-success d-inline-block pr-0"
                            switch
                            size="lg">
                          </b-form-checkbox>
                          <h5
                            style="line-height: 2.2;">
                            {{ questionTypeValue.isRequired ? 'จำเป็น' : 'ไม่จำเป็น' }}
                          </h5>
                        </div>
                        <div
                          v-if="formTemplate.question.length > 1"
                          class="d-flex align-items-center">
                          <div class="vl pr-1"></div>
                          <b-button
                            v-ripple.400="'rgba(200, 0, 0,)'"
                            variant="flat-danger"
                            class="btn-icon rounded-circle"
                            @click="removeQuestionType(index)"
                          >
                            <b-img
                              src="@/assets/images/icons/solar_trash-bin-2-outline.svg"
                              alt="solar_trash-bin-2-outline"></b-img>
                          </b-button>
                        </div>
                      </div>
                    </b-card>
                  </div>
                </draggable>
              </b-col>
            </b-row>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              style="height: 40px;"
              variant="outline-primary"
              class="mr-2 btn-submit"
              @click="addQuestionType()"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50 mr-md-0 mr-lg-0 mr-xl-50" />
              <span class="align-middle">เพิ่มคำถาม</span>
            </b-button>
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2 btn-submit"
                type="submit"
                @click="editFormTemplate(formTemplate)">
                Save
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                class="btn-submit"
                variant="outline-secondary"
                @click="backRoute()">
                Cancel
              </b-button>
              <b-button
                variant="outline-danger"
                class="btn-icon ml-auto"
                @click="openDeleteModal()">
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </div>
          </b-form>
        </ValidationObserver>
      </template>
    </b-card>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
import SelectField from '@/components/Form/SelectField.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FormTemplateProvider from '@/resources/FormTemplateProvider'

const FormTemplateService = new FormTemplateProvider()

export default {
  components: {
    TextFieldInput,
    SelectField,
    draggable
  },
  data () {
    return {
      isDeleteModalActive: false,
      formTemplate: {
        name: '',
        question: [
          {
            title: '',
            type: '',
            maximumScore: null,
            isRequired: false
          }
        ]
      },
      typeAnswerOptions: [
        { label: 'ข้อความ', value: 'message' },
        { label: 'ตัวเลข', value: 'number' }
      ],
      enabled: true,
      dragging: false
    }
  },
  computed: {
    formTemplateId () {
      return this.$route.params.id
    }
  },
  created () {
    this.getFormTemplateById(this.formTemplateId)
  },
  methods: {
    async getFormTemplateById (id) {
      try {
        const data = await FormTemplateService.findOne(id)
        this.formTemplate.name = data.data.name
        // for (let i = 0; i < data.data.formQuestions.length; i++) {
        //   this.formTemplate.question[i] = data.data.formQuestions[i]
        // }
        const formQuestions = data?.data?.formQuestions
        if (Array.isArray(formQuestions)) {
          this.formTemplate.question = formQuestions.map((item) => ({
            title: item?.title,
            type: item?.type,
            maximumScore: item?.maximumScore,
            isRequired: item?.isRequired
          }))
        }

        console.log('data', data.data)
      } catch (error) {
        console.log('error', error)
      }
    },
    resetForm () {
    },
    addQuestionType () {
      this.formTemplate.question.push({
        title: '',
        type: '',
        maximumScore: null,
        isRequired: false
      })
    },
    removeQuestionType (index) {
      this.formTemplate.question.splice(index, 1)
    },
    backRoute () {
      this.$router.push({ name: 'form-template' })
    },
    async editFormTemplate (val) {
      try {
        const payload = { ...val }
        const data = await FormTemplateService.update(this.formTemplateId, payload)
        console.log('data', data)
        this.$router.push({ name: 'form-template' })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Updated Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Form Template has been updated'
          }
        })
      } catch (error) {
        console.error(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: '😞 Form Template has been Failed'
          }
        })
      }
    },
    async confirmDelete (val) {
      try {
        await FormTemplateService.softDelete(val)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Form Template has been deleted'
          }
        })
        this.$router.push({ name: 'form-template' })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: `😞 Form Template has been Failed ${error}`
          }
        })
      }
    },
    openDeleteModal () {
      this.isDeleteModalActive = true
    },
    cancelDeleteModal () {
      this.isDeleteModalActive = false
    }
  }
}
</script>

<style lang="scss" scoped>
.vl {
  border-left: 1px solid black;
  height: 40px;
}
.btn--delete {
  cursor: pointer;
}

.padding--top {
  padding-top: 0px;
}

div::v-deep .custom-control-label {
  padding-left: 0px;
}
</style>
